define("discourse/plugins/discourse-wyzecam/discourse/components/about-user-card", ["exports", "discourse/components/user-card-contents"], function (_exports, _userCardContents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _userCardContents.default.extend({
    elementId: null,
    classNames: ["about-user-card"],
    _close() {}
  });
});