define("discourse/plugins/discourse-wyzecam/discourse/initializers/wyzecam-edits", ["exports", "@ember/service", "@ember/utils", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse/models/user", "discourse/models/user-badge", "discourse/routes/about"], function (_exports, _service, _utils, _ajax, _pluginApi, _user, _userBadge, _about) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "wyzecam-edits",
    after: "inject-objects",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.27", api => {
        api.modifyClass("controller:about", {
          router: (0, _service.inject)(),
          actions: {
            showUser(user) {
              this.router.transitionTo("user", user);
            }
          }
        });
      });
      _about.default.reopen({
        model() {
          return (0, _ajax.ajax)("/about.json").then(result => {
            const about = result.about;
            about.user_badges = result.user_badges;
            about.badges = result.badges;
            about.badge_types = result.badge_types;
            return about;
          });
        },
        afterModel(model) {
          model.groups.forEach(group => {
            group.group_users = [];
            group.users.forEach(u => {
              const user = _user.default.create(u);
              if (!(0, _utils.isEmpty)(user.featured_user_badge_ids)) {
                const userBadgesMap = {};
                _userBadge.default.createFromJson(model).forEach(userBadge => {
                  userBadgesMap[userBadge.get("id")] = userBadge;
                });
                user.featured_user_badges = user.featured_user_badge_ids.map(id => userBadgesMap[id]);
              }
              group.group_users.push(user);
              group.group_users.sort(function (a, b) {
                return a.username.localeCompare(b.username);
              });
            });
          });
          return model;
        }
      });
    }
  };
});